import React, { useContext, lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
    LOGIN,
    DASHBOARD,
    EXPOSITORES,
    CREDENCIALES,
    EXPOSITORESCREDENCIALES,
    PREREGISTRO,
    EXPOSITORESFICHA,
} from 'settings/constants'
import AuthProvider, { AuthContext } from 'context/auth'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
const PointOfSale = lazy(() => import('containers/PointOfSale/Pos'))
const Credentials = lazy(() => import('containers/PointOfSale/Print'))
const Ficha = lazy(() => import('containers/PointOfSale/Ficha'))
const AdminLayout = lazy(() => import('containers/Layout/Layout'))
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'))
const Login = lazy(() => import('containers/Login/Login'))
const NotFound = lazy(() => import('containers/NotFound/NotFound'))
const Credenciales = lazy(() => import('containers/Credenciales'))
const PreRegistro = lazy(() => import('containers/PreRegistro'))

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
    const { isAuthenticated, dataUser } = useContext(AuthContext)

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

function PrivateAdminRoute({ children, ...rest }) {
    const { isAuthenticated, dataUser } = useContext(AuthContext)

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated && dataUser.role === 'ADMIN' ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

const Routes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InLineLoader />}>
                <Switch>
                    <PrivateRoute exact={true} path={DASHBOARD}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader />}>
                                <Dashboard />
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={CREDENCIALES}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader />}>
                                <Credenciales />
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={EXPOSITORESFICHA}>
                        <Suspense fallback={<InLineLoader />}>
                            <Ficha />
                        </Suspense>
                    </PrivateRoute>
                    <PrivateAdminRoute path={EXPOSITORES}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader />}>
                                <PointOfSale />
                            </Suspense>
                        </AdminLayout>
                    </PrivateAdminRoute>
                    <PrivateAdminRoute path={EXPOSITORESCREDENCIALES}>
                        <Suspense fallback={<InLineLoader />}>
                            <Credentials />
                        </Suspense>
                    </PrivateAdminRoute>

                    <PrivateAdminRoute path={PREREGISTRO}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader />}>
                                <PreRegistro />
                            </Suspense>
                        </AdminLayout>
                    </PrivateAdminRoute>
                    <Route path={LOGIN}>
                        <Login />
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    )
}

export default Routes
